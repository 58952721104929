<template>
  <v-app full-height>
    <v-main>
      <div
        style="height: 100vh"
        class="w-100 d-flex align-center justify-center flex-column"
      >
        <div
          class="d-flex flex-column align-center justify-center"
          style="max-width: 552px;"
        >
          <v-img
            class="flex-grow-1 flex-shrink-1"
            :width="content.imgSize"
            :height="content.imgSize"
            :src="content.img"
          />
          <p class="text-h5 font-weight-medium px-2 mt-4 text-center">{{ content.title }}</p>
          <p class="body-1 px-2 mt-4 text-center text-ui-kit-text-secondary">{{ content.subtitle }}</p>
          <v-btn
            class="mt-6"
            :to="{ name: 'index' }"
          >
            На главную
          </v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
const props = defineProps({
  error: { type: Object, required: true }
})

const { width } = useWindowSize()

const content = computed(() => ({
  title: props.error?.statusCode === 404 ? 'На этой странице ничего нет' : 'Что-то пошло не так',
  subtitle: props.error?.statusCode === 404
    ? 'Возможно, материал удалили или перенесли на другую страницу. Или вы перешли по ссылке, в которой была ошибка'
    : 'Попробуйте немного подождать и обновить страницу',
  img: props.error?.statusCode === 404 ? '/errors/404.png' : '/errors/500.svg',
  imgSize: width.value > 500 && props.error?.statusCode === 404 ? 250 : 175
}))
</script>
